let key = {};
if (process.env.REACT_APP_MODE === "production") {
    console.log("Set Production Config")


    const API_URL = 'https://api.seagold.io'
    key = {
        API_URL: `${API_URL}`,
        Front_URL: "https://www.seagold.io",
        tradecontract: "0x69A8d0a6eaDe8e45C71d6C512F89C0CcE52aad50",
        network: 137,
    };

    // const API_URL = 'https://seagoldapi.wearedev.team'
    // key = {
    //      API_URL: `${API_URL}`,
    //      Front_URL: "https://seagold-frontend.pages.dev",
    //      tradecontract       : "0x4876ce51ff158016FcCb5F30333c07cFB6A415F2",
    //      network : 137,
    //     };
} else {
    console.log("Set Development Config")
    const API_URL = 'http://localhost'
    // const API_URL = 'https://seagoldapi.wearedev.team'

    key = {
        API_URL: `${API_URL}:3513`,
        // API_URL: `${API_URL}`,

        Front_URL: "https://seagold-frontend.pages.dev",
        tradecontract: "0x4876ce51ff158016FcCb5F30333c07cFB6A415F2",
        network: 80001,
    };
}


export default key;