module.exports = {

     // baseUrl: "https://seagoldapi.wealwin.com", //demo

     baseUrl: "https://api.seagold.io", //live


     tradecontract: "0x69A8d0a6eaDe8e45C71d6C512F89C0CcE52aad50",

     presalecontract: "0xebed8f7b035429c10f2299bcd19e2f1d9fc847d8",

     // Recaptchakey : "6LeQQBgnAAAAACW_QSY6PzqWFsUSwg_09QEq9T3H",
     Recaptchakey: "6Ldc1kEoAAAAAOuOrtJk4zIyV8IhZz0Y5UmBvQXT",

     network: "1",

     // frontUrl: "https://seagold-frontend.pages.dev/",  //demo

     frontUrl: "https://www.seagold.io/", //live

     // API_URL: "https://seagoldapi.wealwin.com"   //demo

     API_URL: "https://api.seagold.io" //live

}